<template>
    <div class="login">
        <div class="texture">
            <Row class="login_wrapper">
                <Col span="24">
                    <div class="login_logo">
                        <img src="../assets/images/login_logo.png" alt="资产管理系统">
                    </div>
<!--                    <div class="login_qr">-->
<!--                        <img src="../assets/images/login_qr.png" alt="钉钉扫码登录">-->
<!--                    </div>-->
                    <div class="login_image">
                        <img src="../assets/images/login_image.png" alt="">
                    </div>
                </Col>
            </Row>
            <Row class="copyright">
                <p>Copyright &copy; {{ (new Date).getFullYear() }} 保留所有权利<br>
                    最佳使用环境：操作系统 Win 7 以上、MACOSX，分辨率1440 x 900以上 | 浏览器：IE 10以上、Edge、Chrome 55以上、Firefox 50.0以上、Safari
                    9.0以上
                </p>
            </Row>
        </div>
    </div>
</template>

<script>
import {getCode, reqDingAuthority} from "../api/dingDing-api";

export default {
    name: "dingLogin",
    data() {
        return {
        }
    },
    created() {
        getCode(code => {
            if (code) {
                reqDingAuthority({authCode: code}).then(res => {
                    if (res.code === 1) {
                        sessionStorage.setItem('un', res.data.name);
                        sessionStorage.setItem('Authorization', res.data.token);
                        this.$router.push({path: '/'});
                    } else if (res.code === 8) {
                        sessionStorage.setItem('un', res.data.name);
                        sessionStorage.setItem('Authorization', res.data.token);
                        this.$router.push({path: '/UnauthorizedPage'});
                    } else {
                        this.$Message.error('登录失败');
                    }
                }).catch(() => {
                    this.$Message.error('登录异常');
                })
            } else {
                this.$Message.error('免登授权码不正确');
            }
        })
    }
}
</script>
<style lang="less">
// Related style
@import '../index.less';

.login {
    height: 100%;
    background-color: @body-background;
    background-image: url(../assets/images/bg.jpg);
    background-repeat: repeat-x;
    background-position: left top;

    .texture {
        height: 100%;
        background-image: url(../assets/images/texture.png);
        background-repeat: no-repeat;
        background-position: center center;

        .login_wrapper {
            width: 480px;
            margin: 0 auto;
            padding-top: 12%;

            .login_logo {
                text-align: center;
            }

            .login_qr {
                padding: 32px;
                text-align: center;
                img {
                    display: inline-block;
                    padding: 32px;
                    background-color: #fff;
                    border: 1px solid #eee;
                    border-radius: 12px;
                }
            }

            .login_image {
                margin-top: 20px;
                text-align: center;

                img {
                    width: 80%;
                }
            }
        }

        .copyright {
            position: fixed;
            bottom: 20px;
            display: block;
            width: 100%;
            text-align: center;
            color: @text-color-secondary;
            line-height: 20px;
        }
    }
}
</style>
